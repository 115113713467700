@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');

.resume-page {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    
}

.animation-swipe-out-left-to-right{
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100%);
    transition: all 1s;
}

.animation-swipe-in-left-to-right{
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
    transition: all 1s;
}

.monitor {
    flex: 1;
    min-height: 100vh;
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
    max-width: 1034px;
}

.window {
    margin: 0 auto;
    margin-top: 2%;
    width: 90%;
    border: #C0C0C0;
    border-style: solid;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-shadow: 1px 2px 10px;
}

.titlebar {
    width: 100%;
    height: 38px;
    display: flex;
    background-image: linear-gradient(to right, #000180, #1084d0);
    flex-direction: row;
}

.second-titlebar{
    width: 100%;
    height: 38px;
    background-color: #C0C0C0; 
    flex-direction: row;
    display: none;
}

.lista-botoes {
    width: 100%;
    max-width: 260px;
    min-height: 85vh;
}

.content {
    width: calc(100% - 260px);
    /* background-image: url("https://img.freepik.com/free-photo/design-space-paper-textured-background_53876-42312.jpg?w=2000"); */
}

.inner-content {
    margin: 10%;
}

.resume-logo {
    max-width: 20%;
}

@media only screen and (max-width: 768px) {
    .second-titlebar{
        display: flex;
    }
    .lista-botoes {
        display: none;
    }
    .content {
        width: calc(100%);
        min-height: 85vh;
    }
}