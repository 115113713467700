.presentation-page {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    flex-wrap: wrap;
    animation: fadeIn 3s;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

.avatar {
    width: 300px !important;
    height: 300px !important;
    margin-top: 80px
}

@font-face {
    font-family: accidental_presidency;
    src: local("THEBOLDFONT"),
        url("../../../fonts/accidental_presidency/accid___.ttf") format("truetype");
}

.name {
    margin: 0;
    font-size: 50pt;
}

.title {
    margin: 0;
    font-size: 30pt;
}

@media only screen and (max-width: 590px) {
    .name {
        font-size: 40pt;
    }

    .title {
        font-size: 26pt;
    }

    .avatar {
        margin-top: 30px;
    }
}