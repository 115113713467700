
.social-network-white-background{
  background-image: linear-gradient(180deg, white 50%, white 50%);
  background-size: 16px 13px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 1px
}

.select-mobile-screen{
    display: none !important;
  }
  
  .break-line-mobile-screen{
    display: none;
  }
  
  
  @media only screen and (max-width: 570px) {
    .select-pc-screen {
        display: none !important;
    }
    .select-mobile-screen{
      display: flex !important;
    }
    .social-network-buttons{
      margin: 0 auto;
    }
    .break-line-mobile-screen{
      display: flex;
    }
  }