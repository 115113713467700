.about-me-page{
    display: flex;
    align-items: center;
    justify-content: space-around; 
    flex-direction: row;
    flex-wrap: wrap;
}

.animation-swipe-out-right-to-left{
    opacity: 0;
    filter: blur(5px);
    transform: translateX(100%);
    transition: all 1s;
}

.animation-swipe-in-right-to-left{
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
    transition: all 1s;
}


.about-me-text{
    max-width: 70%;
    align-self: baseline;
}

.space-text-next-page{
    width: 0px;
    height: 70px;
    display: none;
}

@media only screen and (max-width: 956px) {
    .marco-presenting {
        display: none;
    }
    .about-me-text{
        max-width: 90%;
    }
    .space-text-next-page{
        display: flex;
    }
    
}