.app {
  text-align: center;
  /* background-color: #F2F2F2; */
  display: flex;
  flex-direction: column;
}

.app-content {
  max-width: 1440px;
  align-self: center;
}
body{
  overflow-x:hidden
}

@media (prefers-reduced-motion) {
  .app{
    transition: none
  }
}